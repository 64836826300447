<i18n locale="th" lang="yaml" src="@i18n/service/onfield-service.th.yaml"></i18n>
<i18n locale="th" lang="yaml">
onfield_bind_ticket.page.title : "ผูก Ticket"
onfield_bind_ticket.page.description : "ขั้นตอนที่ 2/8"
onfield_bind_ticket.subheader : "Ticket ที่เกี่ยวข้อง"
onfield_bind_ticket.description : "แสดงผลเฉพาะ Ticket ที่รายงานก่อนรับเครื่อง 2 เดือน"
onfield_bind_ticket.description.date : "(ตั้งแต่วันที่ {date})"


onfield_bind_ticket.action.cancel_bind.label : "ยกเลิก"
onfield_bind_ticket.action.bind.label : "ผูก Ticket"
onfield_bind_ticket.action.next.page : "ไปขั้นตอนถัดไป"

onfield_bind_ticket.confirm.title : "กรุณายืนยันเพื่อดำเนินการต่อไป"
onfield_bind_ticket.confirm.message : "ต้องการดำเนินการไปยังขั้นตอนถัดไปหรือไม่?"
onfield_bind_ticket.success : "ดำเนินการเรียบร้อย"


</i18n>

<template>
	<div class="page page-padding page-fit-mobile">
		<my-page-header
			class="main-header"
			:title="$t('onfield_bind_ticket.page.title')"
			:description="$t('onfield_bind_ticket.page.description')"/>
		<div class="sub-header">
			{{ $t('onfield_bind_ticket.subheader') }}
		</div>

		<div class="link-ticket-modal-content">
			<ErrorMessagePane :error="processError" />

			<a-list item-layout="vertical" :data-source="tickets" :pagination="pagination" :loading="loading" class="ticket-list">
				<TicketSelectableCard
					slot="renderItem"
					key="ticket.id"
					slot-scope="ticket"
					:ticket="ticket"
					size="small"
					:bordered="true"
					link-target="_blank"
					:actived="ticket.actived"
					:disabled="ticket.status == 'cancel'"
					:clickable="true"
					:link-clickable="false"
					:modal-mode="true">
					<div slot="action">
						<span v-if="ticket.actived">
							<a-icon type="link" class="link-status-icon text-success" />
							<a-button size="small" class="btn-danger-outline" @click="clickCancel($event, ticket)">
								{{ $t('onfield_bind_ticket.action.cancel_bind.label') }}
							</a-button>
						</span>
						<span v-else>
							<a-button size="small" @click="clickBind($event, ticket)">
								{{ $t('onfield_bind_ticket.action.bind.label') }}
							</a-button>
						</span>
					</div>
				</TicketSelectableCard>
			</a-list>
			<div slot="footer" class="ticket-description">
				{{ $t('onfield_bind_ticket.description') }}
				<span v-if="$notEmpty(ticketStartDate)">
					{{ $t('onfield_bind_ticket.description.date', { date: $dayjs(ticketStartDate).format('LL') }) }}
				</span>
			</div>
			<div class="next-button page-action-right">
				<a-button icon="right-circle" class="page-action-right" type="primary" @click="handleNextPage">
					{{ $t('onfield_bind_ticket.action.next.page') }}
				</a-button>
			</div>
		</div>
	</div>
</template>

<script>
import TicketSelectableCard from '@components/ticket/TicketSelectableCard.vue'
import axios from 'axios'
import ErrorMessagePane from '@components/common/ErrorMessagePane.vue'
import { List } from 'ant-design-vue'

export default {
	components: {
		TicketSelectableCard,
		ErrorMessagePane,
		'a-list': List,
	},
	props: {
		service: {
			type: null,
			default: () => [],
		},
	},
	data() {
		return {
			loading: false,
			processError: undefined,
			tickets: [],
			ticketStartDate: undefined,
			pagination: {
				pageSize: 5,
				current: 1,
				hideOnSinglePage: true,
				onChange: (page) => {
					this.pagination.current = page
				},
			},
			linkedTickets: [],
		}
	},
	watch: {
		service: {
			handler() {
				if (this.service.onfield_state && this.service.onfield_state !== 'bind_ticket') {
					this.$router.replace({ name: 'onfield/base', params: { id: this.service.id } })
				} else {
					this.fetchLinkableTickets()
				}
			},
			immediate: true,
			deep: true,
		},
	},
	methods: {
		fetchLinkableTickets() {
			if (this.service.id) {
				this.loading = true
				this.processError = undefined
				this.ticketStartDate = undefined
				axios
					.get('/api/services/linkable-tickets/' + this.service.id)
					.then((response) => {
						this.processTickets(response.data.data)
					})
					.catch((error) => {
						this.processError = error
					})
					.finally(() => {
						this.loading = false
					})
			}
		},
		processTickets(responseData) {
			this.ticketStartDate = responseData.ticketStartDate
			this.tickets = []
			const currentTicketIdList = responseData.linkTickets.map((linkTicket) => linkTicket.ticket_id)
			this.pagination.current = 1
			for (const ticket of responseData.tickets) {
				ticket.actived = currentTicketIdList.includes(ticket.id)
				if (!ticket.actived && ticket.status == 'cancel') {
					continue
				}
				this.tickets.push(ticket)
			}
		},
		clickBind(event, ticket) {
			if (!ticket.id || ticket.actived) return
			this.loading = true
			this.processError = undefined

			axios
				.post(`/api/services/bind-ticket/${this.service.id}`, { ticket_id: ticket.id })
				.then(() => {
					ticket.actived = true
				})
				.catch((error) => {
					this.processError = error
				})
				.finally(() => {
					this.loading = false
				})
			event.stopPropagation()
		},
		clickCancel(event, ticket) {
			if (!ticket.id || !ticket.actived) return
			this.loading = true
			this.processError = undefined
			axios
				.post(`/api/services/bind-cancel-ticket/${this.service.id}`, { ticket_id: ticket.id })
				.then(() => {
					ticket.actived = false
				})
				.catch((error) => {
					this.processError = error
				})
				.finally(() => {
					this.loading = false
				})
			event.stopPropagation()
		},
		handleNextPage() {
			this.$confirm({
				title: this.$t('onfield_bind_ticket.confirm.title'),
				content: this.$t('onfield_bind_ticket.confirm.message'),
				okText: this.$t('common.ok'),
				cancelText : this.$t('common.cancel'),
				maskClosable: true,
				onOk: () => {
					this.loading = true
					axios
						.post(`/api/services/${this.service.id}/update-onfield-state`, { state: 'pre_service' })
						.then(() => {
							this.$message.success(this.$t('onfield_bind_ticket.success'))
							this.$router.push({ name: 'onfield/pre_service', params:{id:this.service.id} })
							})
							.catch((error) => {
								this.processError = error
							})
							.finally(() => {
								this.loading = false
							})
				},
			})
		},
	},
}
</script>

<style lang="less" scoped>
.link-status-icon {
	font-size: 1.5em;
	margin-bottom: 8px;
	display: block;
}
.ticket-list {
	min-height: 300px;
}
.ticket-description {
	text-align: right;
	font-size: 0.9em;
}
.main-header {
	text-align: center;
}
.sub-header {
	font-weight: 550;
	color: #14837b;
	background-color: #fefefe;
	padding: 5px;
	margin-left: 0;
	margin-bottom: 10px;
}
.page-action-right {
	float: right;
	margin-bottom : 8px;
}
</style>
